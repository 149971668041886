import gql from 'graphql-tag'
import { Store } from '@/graphql/fragments'

export const EditSite = gql`
  mutation editSite($input: EditSiteInput!) {
    editSite(input: $input) {
      clientMutationId
      success
    }
  }
`

export const CreateSite = gql`
  mutation createSite($input: CreateSiteInput!) {
    createSite(input: $input) {
      clientMutationId
      site {
        siteId
      }
    }
  }
`
